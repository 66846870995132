<template>
  <div>
    <app-breadcrumb />
    <b-card>
      <ais-instant-search
        :search-client="searchClient"
        index-name="customer"
      >
        <ais-search-box
          placeholder="Cari customer (bisa gunakan nama atau nomor hp)"
          :class-names="{
            'ais-SearchBox-input': 'form-control',
            'ais-SearchBox-submit': 'd-none',
            'ais-SearchBox-reset': 'd-none',
          }"
          class="mt-1"
        >
          <div
            slot="submit-icon"
          >
            Cari
          </div>
        </ais-search-box>
        <ais-infinite-hits
          :class-names="{
            'ais-InfiniteHits-list' : 'hitsList',
          }"
        >
          <div
            slot="item"
            slot-scope="{ item }"
            class="mt-1"
          >
            <b-card
              border-variant="primary"
              class="m-0 p-1"
              no-body
            >
              <div class="d-flex justify-content-between">
                <div class="d-inline-block">
                  <router-link
                    class="font-weight-bold d-block text-nowrap"
                    :to="'/customer/' + item.objectID"
                  >
                    <h6
                      class="text-primary font-weight-bolder m-0"
                      style="cursor: pointer"
                    >
                      {{ item.nama }}
                    </h6>
                    <small
                      class="text-secondary m-0"
                    >
                      {{ item.no_hp }}
                    </small>
                  </router-link>
                </div>
                <div>
                  <Promised :promise="getSaldo(item.no_hp)">
                    <template v-slot:pending>
                      loading...
                    </template>
                    <template v-slot="data">
                      <b-badge
                        class="mr-50"
                        :variant="data == 0 ? 'light-danger' : 'light-primary'"
                      >
                        <small class="mr-25 text-secondary">Saldo</small>
                        {{ data }}
                      </b-badge>
                    </template>
                  </Promised>
                  <template>
                    <b-dropdown
                      id="dropdown-right"
                      right
                      variant="link"
                      toggle-class="p-0"
                      no-caret
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item
                        @click="bottomSheetBlokirCustomerOpen(item.objectID)"
                      >
                        <feather-icon icon="UserIcon" />
                        <span class="align-middle ml-50">Blokir customer</span>
                      </b-dropdown-item>
                      <b-dropdown-divider />
                      <b-dropdown-item
                        @click="hapusCustomer(item.objectID)"
                      >
                        <feather-icon
                          class="text-danger"
                          icon="CopyIcon"
                        />
                        <span class="align-middle ml-50 text-danger">Hapus customer</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </div>
              </div>
            </b-card>
          </div>
          <div
            slot="loadMore"
            slot-scope="{ refineNext }"
            class="d-flex"
          >
            <button
              class="btn btn-primary mx-auto"
              @click="refineNext"
            >
              Lihat Lebih Banyak
            </button>
          </div>
        </ais-infinite-hits>
      </ais-instant-search>
    </b-card>

    <vue-bottom-sheet
      ref="tampilkanBlokirCustomer"
      :rounded="false"
      :click-to-close="false"
      variant="success"
      block
    >
      <div class="bottom-sheet">
        <div class="d-flex justify-content-between mb-1 align-content-center">
          <h4>Blokir Customer</h4>
          <feather-icon
            icon="XIcon"
            size="28"
            class="text-primary"
            @click="bottomSheetBlokirCustomerClose"
          />
        </div>
        <hr>
        <div class="d-flex flex-column pb-5">
          <h5>Pilih layanan yang akan diblokir</h5>
          <v-select
            v-model="selectLayanan"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="label"
            multiple
            :options="layananOptions"
            placeholder="Pilih layanan"
            class="mt-1"
          />
          <b-button
            variant="success"
            class="mt-1"
            @click="tambahBlockLayanan(currentIdBottomSheet)"
          >
            Blokir Customer
          </b-button>
        </div>
      </div>
    </vue-bottom-sheet>
  </div>
</template>

<script>
/* eslint-disable arrow-body-style */
/* eslint-disable vue/no-unused-components */
/* eslint-disable radix */
/* eslint-disable no-unused-vars */
import {
  BCard, BDropdown, BDropdownItem, BDropdownDivider, BBadge,
  BButton,
} from 'bootstrap-vue'
import algoliasearch from 'algoliasearch/lite'
import { AisInstantSearch, AisSearchBox } from 'vue-instantsearch'
import { Promised } from 'vue-promised'
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { db } from '@/firebase'

export default {
  components: {
    BCard,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BBadge,
    BButton,

    AisInstantSearch,
    AisSearchBox,
    Promised,
    AppBreadcrumb,

    VueBottomSheet,
    vSelect,
    ToastificationContent,
  },
  data() {
    return {
      currentIdBottomSheet: '',
      searchClient: algoliasearch(
        '4LASHL8CBV',
        '0f873fc12e5ca3f430fbe1014a65c384',
      ),
      layananOptions: [
        { value: 'vl01', label: 'Akupuntur' },
        { value: 'vl02', label: 'Auto' },
        { value: 'vl03', label: 'Barber' },
        { value: 'vl04', label: 'Bekam' },
        { value: 'vl05', label: 'Beauty' },
        { value: 'vl06', label: 'Cleaning' },
        { value: 'vl07', label: 'Massage' },
        { value: 'vl08', label: 'Cuci AC' },
      ],
      selectLayanan: '',
    }
  },
  methods: {
    async getSaldo(noHp) {
      let saldo = 0
      const no = noHp.substring(1)
      const options = {
        url: 'https://us-central1-mecare-life.cloudfunctions.net/getSaldoMitra',
        method: 'POST',
        data: {
          no_hp: no,
        },
      }
      await this.$axios(options).then(res => {
        saldo = parseInt(res.data)
      })

      return saldo
    },
    tambahBlockLayanan(id) {
      const layananBlock = []

      this.selectLayanan.forEach(vertikal => {
        layananBlock.push(vertikal.value)
      })

      db.collection('customer').where('objectID', '==', id).get().then(() => {
        db.collection('customer').doc(id).set({
          block_layanan: layananBlock,
        }, { merge: true })
      })
        .then(() => {
          this.bottomSheetBlokirCustomerClose()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Blokir Customer Success',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
    },
    hapusCustomer(objectID) {
      this.$swal({
        title: 'Hapus data customer ?',
        text: 'Konfirmasi jika anda ingin menghapus data customer',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const options = {
            url: 'https://us-central1-mecare-life.cloudfunctions.net/deleteCustomerWithAlgolia',
            method: 'POST',
            data: {
              uid: objectID,
            },
          }
          axios(options).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Silahkan refresh mecarehub anda',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          })
        }
      })
    },
    bottomSheetBlokirCustomerOpen(id) {
      this.currentIdBottomSheet = id
      this.$refs.tampilkanBlokirCustomer.open()
    },
    bottomSheetBlokirCustomerClose() {
      this.$refs.tampilkanBlokirCustomer.close()
    },
  },
}
</script>

<style>
  .hitsList {
    list-style-type: none !important;
    padding-left: 0;
  }

  .bottom-sheet {
    padding: 16px 16px 0px 16px
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
